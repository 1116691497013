// IMPORTANT TODO: phase out 'Header.tsx' and 'NewHeader.tsx'
import React from 'react';

import { useMemo, useState } from 'react';
import styled from 'styled-components';

import analytics from '../helpers/analytics';

import LinkButton from './LinkButton';

import menuCloseIcon from '../icons/close.svg';
import menuHambugerIcon from '../icons/hamburger-menu.svg';
import presentIcon from '../icons/present-icon.svg';
import userIcon from '../icons/user-icon.svg';
import mobileMenuBg from '../images/mobile-menu-bg.png';
import illumeIcon from '../images/new-logo.png';

import { Link } from 'gatsby';
import {
  HOME_LINK,
  HOWITWORKS_LINK,
  OCCASIONS_LINK,
  PRICING_B2C_LINK,
  SIGN_IN_LINK,
  SLACK_LINK,
  SLACK_ONBOARDING_LINK,
  SLACK_PRICING_LINK,
  START_NOW_LINK,
} from '../constants/links';
import useFormFactors from '../helpers/useFormFactors';
import { getMainAppFullPath } from '../constants/getUrl';

const TRACK_LOGIN = 'login - marketing';

const Layout = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  z-index: 10;
  ${(props) =>
    props.isMobile
      ? `
      align-items: center;
      padding-top: 10px;`
      : `
    height: 64px;
    padding: 0 8px;
  `}

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 80px;
    padding: 8px 16px;
    width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 96px;
    padding: 16px 24px;
    width: 100%;
  }
`;

const Home = styled.a`
  padding: 8px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0;
  }
`;

const HomeImage = styled.img`
  width: 65px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 8px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 8px 0;
  }
`;

const Actions = styled.div`
  display: flex;
  padding: 16px 0;
`;

export const CreateLink = styled(LinkButton)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  max-width: 352px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    align-self: center;
    font-size: 18px;
    line-height: 25.74px;
    width: 214px;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

const Login = styled(LinkButton)`
  background: transparent;
  color: ${(props) => props.theme.colors.blackText};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  padding: 7px 0 7px 14px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    align-self: center;
    font-size: 18px;
    line-height: 25.74px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;

const MenuIcons = styled.div<{ menuExpanded?: boolean }>`
  position: relative;
  margin: ${(props) => (props.menuExpanded ? '-15px -15px 0 0' : '0')};
  padding: 15px;
  z-index: 3;
`;

const MenuItems = styled.div`
  position: absolute;
  z-index: 2;
  padding: 30px 0 0 0;
  width: 100px;
  animation: animateMenuIn ease-in-out 0.4s both;

  @keyframes animateMenuIn {
    from {
      transform: translateX(70px) scale(0.75);
    }
    to {
      transform: translateX(0px) scale(1);
    }

`;

const MenuItem = styled.a`
  display: flex;
  text-decoration: none;
  padding: 10px 0;
  justify-content: flex-end;
  align-items: center;
  color: #fff;

  img {
    margin-right: 5px;
    width: 22px;
    height: 22px;
  }
`;

const MenuItemTextContainer = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  align-items: end;
  color: #fff;
  row-gap: 10px;
  & > li:hover {
    text-decoration: underline;
  }
`;

const MenuBackground = styled.img`
  position: absolute;
  top: -46px;
  right: -128px;
  animation: animateBlobIn ease-in-out 0.45s both;

  @keyframes animateBlobIn {
    from {
      transform: rotate(-20deg) translateX(70px) scale(0.65);
    }
    to {
      transform: rotate(0deg) translateX(0px) scale(1);
    }
  }
`;

const LogoAndMenuContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const DesktopLinksContainer = styled.ul`
  margin-left: 20px;
  display: flex;
  row-gap: 12px;
  list-style-type: none;
  column-gap: 36px;
  font-weight: 700;
  font-size: 18px;
  & > li:hover {
    text-decoration: underline;
  }
`;

const defaultLinks: ({ text: string; link: string } | { text: string; onClick: () => any })[] = [
  { text: 'how it works', link: HOWITWORKS_LINK },
  { text: 'occasions', link: OCCASIONS_LINK },
  { text: 'pricing', link: PRICING_B2C_LINK },
  { text: ' illume for Slack', link: SLACK_LINK },
];

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  additionalLinks?: typeof defaultLinks;
  showCreateGiftBtn?: boolean;
}

const Header = ({ additionalLinks, showCreateGiftBtn = true, ...props }: Props) => {
  const formFactors = useFormFactors();

  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);

  const handleClickLogin = () => {
    analytics.track(TRACK_LOGIN);
  };

  const links = useMemo(
    () => (additionalLinks ? defaultLinks.concat(additionalLinks) : defaultLinks),
    [additionalLinks]
  );

  const renderMobileMenu = () => {
    return (
      <MenuWrapper>
        <MenuIcons onClick={() => setMenuExpanded(!menuExpanded)} menuExpanded={menuExpanded}>
          {menuExpanded ? <img src={menuCloseIcon} /> : <img src={menuHambugerIcon} />}
        </MenuIcons>
        {menuExpanded && (
          <>
            <MenuItems>
              <MenuItemTextContainer>
                {links.map((link, i) => (
                  <li key={i}>
                    {'link' in link ? (
                      link.link.includes('http') ? (
                        <a href={link.link}>{link.text}</a>
                      ) : (
                        <Link to={link.link}>{link.text}</Link>
                      )
                    ) : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          link.onClick && link.onClick;
                        }}
                      >
                        {link.text}
                      </a>
                    )}
                  </li>
                ))}
              </MenuItemTextContainer>

              <MenuItem href={SIGN_IN_LINK} onClick={() => setMenuExpanded(false)}>
                login
              </MenuItem>
            </MenuItems>
            <MenuBackground src={mobileMenuBg} width='262' height='255' />
          </>
        )}
      </MenuWrapper>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <>
        {showCreateGiftBtn && <CreateLink href={START_NOW_LINK}>Create your group gift</CreateLink>}
        <Login href={SIGN_IN_LINK} onClick={handleClickLogin}>
          Login
        </Login>
      </>
    );
  };

  return (
    <Layout isMobile={formFactors.mobile} {...props}>
      <LogoAndMenuContainer>
        <Home href={'/'}>
          <HomeImage alt='' src={illumeIcon} />
        </Home>
        {(formFactors.tablet || formFactors.laptop || formFactors.laptopAll) && (
          <DesktopLinksContainer>
            {links
              .map((l) => ({ ...l, text: capitalize(l.text) }))
              .map((link, i) => (
                <li key={i}>
                  {'link' in link ? (
                    link.link.includes('http') ? (
                      <a href={link.link}>{link.text}</a>
                    ) : (
                      <Link to={link.link}>{link.text}</Link>
                    )
                  ) : (
                    <a
                      href={''}
                      onClick={(e) => {
                        e.preventDefault();
                        return link.onClick && link.onClick();
                      }}
                    >
                      {link.text}
                    </a>
                  )}
                </li>
              ))}
          </DesktopLinksContainer>
        )}
      </LogoAndMenuContainer>
      <Actions>{formFactors.mobile ? renderMobileMenu() : renderDesktopMenu()}</Actions>
    </Layout>
  );
};

export default Header;
